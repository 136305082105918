/**
 * Initialize Matomo on consent
 */
/*
window.matomoLoaded = false;
window.addEventListener('cookieConsent', function (event) {
    if (event.detail.hasOption('matomo') && window.matomoLoaded === false) {
        //
        window.matomoLoaded = true;
    }
});
 */
var _paq = (window._paq = window._paq || []);

// tracker methods like "setCustomDimension" should be called before "trackPageView"
_paq.push(['setDoNotTrack', true]);
_paq.push(['disableCookies']);
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function () {
    //
    // TODO: Change matomo URL below (don’t forget the trailing slash)
    //
    // var u = '//piwik.berliner-stadtmission.org/';
    var u = '//stats.berliner-stadtmission.de/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
})();

/**
 * Component scripts
 */

import Masonry from 'masonry-layout';
import '../components/molecules/lazy-load/_lazy-load.js';
import loadingButton from '../components/atoms/button/_button.js';
import WarningOverlay from '../components/atoms/warning-overlay/_warning-overlay.js';
import Accordion from '../components/molecules/accordion/_accordion.js';
import Breadcrumb from '../components/molecules/breadcrumb/_breadcrumb.js';
import DisclosureNav from '../components/molecules/disclosure-nav/_disclosure-nav.js';
import Dialog from '../components/molecules/dialog/_dialog.js';
import navMobile from '../components/molecules/nav-mobile/_nav-mobile.js';
import Slideshow from '../components/molecules/slideshow/_slideshow.js';
import TeaserHasLink from '../components/molecules/teaser/_teaser.js';
import Header from '../components/organisms/header/_header.js';
import Tabs from '../components/molecules/tabs/_tabs.js';
import tableOfContents from '../components/molecules/toc/_toc.js';
import Toggle from '../components/molecules/toggle-button/_toggle-button.js';
import '../components/molecules/lightbox/_lightbox.js';
import '../components/molecules/consent-dialog/_consent-dialog.js';

/**
 * Set focus style class
 */

(function () {
    // const $html = document.querySelector('html');
    // Let the document know when the mouse is being used,
    // so focus styles are disabled
    // window.addEventListener('mousedown', function () {
    //     $html.classList.add('is-mouse-input');
    // });
    // // Re-enable focus styling when any key is pressed
    // window.addEventListener('keydown', function () {
    //     $html.classList.remove('is-mouse-input');
    // });
})();

/**
 * Initialize component modules
 */

// document.addEventListener('DOMContentLoaded', function () {
//     try {
//         document
//             .getElementById('loading-overlay')
//             .setAttribute('class', 'loading-overlay--is-hidden');
//     } catch (exception) {
//         console.error('Loading Overlay: ' + exception);
//     }
// });

window.tableOfContents = tableOfContents;

const $accordionList = document.getElementsByClassName('js-accordion');
for (const $accordion of $accordionList) {
    new Accordion($accordion).init();
}

const $mainNav = document.querySelector('.js-disclosure-nav');
if ($mainNav) {
    new DisclosureNav($mainNav).init();
}

const $header = document.getElementsByClassName('js-header')[0];
let header;
if ($header) {
    header = new Header($header);
    header.init();
}

const $slideshows = document.getElementsByClassName('js-slideshow');
let slideshow;
if ($slideshows.length) {
    slideshow = new Slideshow($slideshows);
    slideshow.init();
}

const $breadcrumb = document.getElementsByClassName('js-breadcrumb')[0];
if ($breadcrumb) {
    new Breadcrumb($breadcrumb).init();
}

const $teasersHasLink = document.getElementsByClassName('js-teaser--has-link');
for (const $teaserHasLink of $teasersHasLink) {
    new TeaserHasLink($teaserHasLink).init();
}

const $warningOverlays = document.getElementsByClassName('js-warning-overlay');
for (const $warningOverlay of $warningOverlays) {
    new WarningOverlay($warningOverlay).init();
}

const $masonryGrids = document.querySelectorAll('.js-grid-masonry');
for (const $masonryGrid of $masonryGrids) {
    new Masonry($masonryGrid, {
        itemSelector: '.js-grid-masonry > .js-grid-masonry__item',
        percentPosition: true,
        horizontalOrder: true,
    });
}

const $dialogs = document.getElementsByClassName('js-dialog');
for (const $dialog of $dialogs) {
    new Dialog($dialog).init();
}

const $toggles = document.querySelectorAll('.js-toggle');
for (const $toggle of $toggles) {
    new Toggle($toggle, $toggles).init();
}

const $tabs = document.querySelectorAll('.js-tabs');
for (const $tab of $tabs) {
    new Tabs($tab).init();
}

navMobile();
loadingButton();

const tables = document.querySelectorAll(
    'table:not([class]), table.contenttable'
);
for (const table of tables) {
    // create wrapper container
    var wrapper = document.createElement('div');

    // insert wrapper before el in the DOM tree
    table.parentNode.insertBefore(wrapper, table);

    // move el into wrapper
    wrapper.classList.add('c-table');
    wrapper.appendChild(table);
}
