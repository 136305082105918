class WarningOverlay {
    constructor($warningOverlay) {
        this.$warningOverlay = $warningOverlay;
        this.$video = $warningOverlay.parentElement.querySelector('video');
        this.$button = $warningOverlay.getElementsByClassName(
            'js-warning-overlay__button'
        )[0];

        // Bind `this` to methods where needed
        this.addClickListener = this.addClickListener.bind(this);
        this.addTransitionClass = this.addTransitionClass.bind(this);
        this.removeOverlay = this.removeOverlay.bind(this);
    }

    init() {
        if (!this.$warningOverlay || !this.$button) return;

        window.addEventListener('DOMContentLoaded', this.addClickListener);
    }

    addClickListener() {
        window.removeEventListener('DOMContentLoaded', this.addClickListener);
        this.$button.addEventListener('click', this.addTransitionClass);
    }

    addTransitionClass() {
        window.removeEventListener('click', this.addTransitionClass);
        this.$warningOverlay.addEventListener(
            'transitionend',
            this.removeOverlay
        );
        this.$warningOverlay.classList.add('h-is-fading-out');
    }

    removeOverlay(event) {
        if (event.target === this.$warningOverlay) {
            this.$warningOverlay.parentNode.removeChild(this.$warningOverlay);
            if (this.$video) {
                this.playVideo();
            }
        }
    }

    playVideo() {
        this.$video.play();
    }
}

export default WarningOverlay;
