/**
 *
 * Disclosure Menu
 *
 */

const SELECTOR_TOP_LEVEL_ACTION = '.c-nav-main__action';

class DisclosureNav {
    constructor($module) {
        this.rootNode = $module;
        this.useArrowKeys = true;
        this.handleMainNavHide = this.handleMainNavHide.bind(this);
        this.menus = [];
        this.openMenuIndex = null;
        this.topLevelActions = [
            ...this.rootNode.querySelectorAll(SELECTOR_TOP_LEVEL_ACTION),
        ];
    }

    init() {
        if (!this.rootNode || !this.topLevelActions.length) {
            return;
        }

        window.addEventListener('headerHide', this.handleMainNavHide);
        this.rootNode.addEventListener('focusout', this.onBlur.bind(this));

        this.topLevelActions.forEach((action) => {
            const isToggle =
                action.tagName.toLowerCase() === 'button' &&
                action.hasAttribute('aria-controls');

            // Set up all top-level toggle buttons
            if (isToggle) {
                const menuId = action.getAttribute('aria-controls');
                const menu = document.getElementById(menuId);

                // Save a reference of all the menus
                this.menus.push(menu);

                // Make sure all menus are collapsed initially
                action.setAttribute('aria-expanded', 'false');
                this.toggleMenu(menu, false);

                // Attach event listeners
                menu.addEventListener('keydown', this.onMenuKeyDown.bind(this));
                action.addEventListener('click', this.onButtonClick.bind(this));
                action.addEventListener(
                    'keydown',
                    this.onButtonKeyDown.bind(this)
                );
            }

            // Set up all top-level links
            else {
                this.menus.push(null);
                action.addEventListener(
                    'keydown',
                    this.onLinkKeyDown.bind(this)
                );
            }
        });
    }

    handleMainNavHide() {
        this.close();
    }

    moveFocusByKey(event, nodeList, currentIndex) {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > -1) {
                    let prevIndex = Math.max(0, currentIndex - 1);
                    nodeList[prevIndex].focus();
                }
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex > -1) {
                    let nextIndex = Math.min(
                        nodeList.length - 1,
                        currentIndex + 1
                    );
                    nodeList[nextIndex].focus();
                }
                break;
            case 'Home':
                event.preventDefault();
                nodeList[0].focus();
                break;
            case 'End':
                event.preventDefault();
                nodeList[nodeList.length - 1].focus();
                break;
        }
    }

    onBlur(event) {
        let menuContainsFocus = this.rootNode.contains(event.relatedTarget);
        if (!menuContainsFocus && this.openMenuIndex !== null) {
            this.toggleButtonState(this.openMenuIndex, false);
        }
    }

    // const isExpanded = $toggle.getAttribute('aria-expanded') === 'true' || false;
    onButtonClick(event) {
        let clickedButton = event.target;
        let activeButtonIndex = this.topLevelActions.indexOf(clickedButton);
        let isExpanded = clickedButton.getAttribute('aria-expanded') === 'true';
        this.toggleButtonState(activeButtonIndex, !isExpanded);
    }

    onButtonKeyDown(event) {
        let activeButtonIndex = this.topLevelActions.indexOf(
            document.activeElement
        );

        // Close if escape key is pressed
        if (event.key === 'Escape') {
            this.toggleButtonState(this.openMenuIndex, false);
        }

        // On ArrowDown Move focus into the open menu
        else if (
            this.useArrowKeys &&
            this.openMenuIndex === activeButtonIndex &&
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
            this.menus[this.openMenuIndex].querySelector('a').focus();
        }

        // handle arrow key navigation between top-level buttons, if set
        else if (this.useArrowKeys) {
            this.moveFocusByKey(event, this.topLevelActions, activeButtonIndex);
        }
    }

    onLinkKeyDown(event) {
        let targetLinkIndex = this.topLevelActions.indexOf(
            document.activeElement
        );

        // If set, move focus between top-level links
        if (this.useArrowKeys) {
            this.moveFocusByKey(event, this.topLevelActions, targetLinkIndex);
        }
    }

    onMenuKeyDown(event) {
        if (this.openMenuIndex === null) {
            return;
        }
        let menuLinks = Array.prototype.slice.call(
            this.menus[this.openMenuIndex].querySelectorAll('a')
        );
        let currentIndex = menuLinks.indexOf(document.activeElement);

        // Close on escape
        if (event.key === 'Escape') {
            this.topLevelActions[this.openMenuIndex].focus();
            this.toggleButtonState(this.openMenuIndex, false);
        }

        // If set, handle arrow key navigation within menu links
        else if (this.useArrowKeys) {
            this.moveFocusByKey(event, menuLinks, currentIndex);
        }
    }

    toggleButtonState(index, isExpanded) {
        // If another menu is currently open, close it
        if (this.openMenuIndex !== index) {
            this.toggleButtonState(this.openMenuIndex, false);
        }

        // Handle current menu
        if (this.topLevelActions[index]) {
            this.openMenuIndex = isExpanded ? index : null;
            this.topLevelActions[index].setAttribute(
                'aria-expanded',
                isExpanded
            );
            this.toggleMenu(this.menus[index], isExpanded);
        }
    }

    toggleMenu(menu, shouldShow) {
        if (menu) {
            menu.hidden = !shouldShow;
        }
    }

    close() {
        this.toggleButtonState(this.openMenuIndex, false);
    }
}

export default DisclosureNav;
