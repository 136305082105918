import FOCUSABLE_NODES from '../../../scripts/utils/focusable-selectors.js';

const TAB_KEY = 9;
const ESCAPE_KEY = 27;

class Dialog {
    /**
     * Define the constructor to instantiate a dialog
     *
     * @class
     * @param {HTMLElement} node
     */
    constructor(node) {
        this.$module = node;
        this.$content = node.querySelector('.c-dialog__content');
        this.id = node.id;
        this.isShown = false;
        this.role = node.getAttribute('role');
        this.isDismissable = true;
        this.previouslyFocused = null;
        this._events = {};

        // Bind functions that will be used in `addEventListener` and `removeEventListener`
        // so they don’t lose the reference to their dialog instance.

        this._show = this.show.bind(this);
        this._hide = this.hide.bind(this);
        this._dispatchEvent = this._dispatchEvent.bind(this);
        this._maintainFocus = this.maintainFocus.bind(this);
        this._handleKeyDown = this.handleKeyDown.bind(this);
    }

    /**
     * Set up everything necessary for the dialog to be functioning
     *
     */
    init() {
        if (!this.$module) {
            return;
        }

        this.$module.setAttribute('aria-hidden', true);
        this.$module.setAttribute('aria-modal', true);
        this.$module.setAttribute('tabindex', -1);

        if (!this.$module.hasAttribute('role')) {
            this.$module.setAttribute(
                'role',
                this.isAlert ? 'alertdialog' : 'dialog'
            );
        }
        this.$content.setAttribute('role', 'document');

        // Keep a collection of dialog openers
        this.$openButtons = [
            ...document.querySelectorAll(
                '[data-dialog-show="' + this.id + '"]'
            ),
        ];

        // Start listening for clicks on each open button
        this.$openButtons.forEach(($openButton) =>
            $openButton.addEventListener('click', this._show)
        );

        // Keep a collection of dialog closers
        this.$closers = [
            ...this.$module.querySelectorAll('[data-dialog-hide]'),
            ...document.querySelectorAll(
                '[data-dialog-hide="' + this.id + '"]'
            ),
        ];

        // Start listening for clicks on each of the closers
        this.$closers.forEach(($closer) => {
            $closer.addEventListener('click', this._hide);
        });

        // Run the callbacks registered to `init` and emit `init` as a custom event
        this._dispatchEvent('init', event);
        return this;
    }

    /**
     * Show the dialog element, disable all the targets (siblings), trap the
     * current focus within it, listen for some specific key presses and fire
     * all registered callbacks for `show` event.
     */
    show() {
        // Abort if the dialog is already shown
        if (this.isShown) {
            return this;
        }

        // Keep a reference to the element that had focus right before the dialog
        // was eopened (likely the opening button), to be able to restore it later.
        this.previouslyFocused = document.activeElement;

        // Show the Modal and update its state accordingly
        this.$module.removeAttribute('aria-hidden');
        this.isShown = true;

        // Move the focus to the dialog element
        this.moveFocusToDialog();

        // Start listening for the focus event on the body and send it back
        // to the dialog should it ever receive focus, thus ensure the focus
        // stays trapped inside the dialog while it’s open.
        document.body.addEventListener('focus', this._maintainFocus, true);

        // Start listening for TAB and ESC key presses
        document.addEventListener('keydown', this._handleKeyDown);

        // Run the callbacks registered to `show` and emit `show` as a custom event
        this._dispatchEvent('show', event);
    }

    /**
     * Hide the dialog element, re-enable its siblings, restore the focus to the
     * previously active element, stop listening for some specific key presses
     * and fire all registered callbacks for `hide` event.
     */
    hide() {
        // Abort if the dialog is already hidden
        if (!this.isShown) {
            return this;
        }

        // If we have a reference to an element which was a focused
        // right before the dialog was opened and if that element has
        // a `focus()` method, then restore the focus back to it.
        if (this.previouslyFocused && this.previouslyFocused.focus) {
            this.previouslyFocused.focus();
        }

        // Hide the Modal and update its state
        this.$module.setAttribute('aria-hidden', 'true');
        this.isShown = false;

        // Remove event listener we only need while the modal is shown
        document.removeEventListener('keydown', this._handleKeyDown);
        document.body.removeEventListener('focus', this._maintainFocus, true);

        // Run the callbacks registered to `hide` and emit `hide` as a custom event
        this._dispatchEvent('hide', event);
    }

    /**
     * Destroy the current instance (after making sure the dialog has been
     * hidden) and remove all associated listeners from dialog openers and closers
     */
    destroy() {
        // Remove the click event listener from all dialog closers
        this.$closers.forEach(($closer) =>
            $closer.removeEventListener('click', this._hide)
        );

        // Run the callbacks registered to `destroy` and emit `destroy` as a custom event
        this._dispatchEvent('destroy', event);
    }

    /**
     * Subscribe a callback to a given event
     *
     * @param {String} name - Event name
     * @param {Function} callback
     * @returns {Object}
     */
    on(name, callback) {
        if (typeof this._events[name] === 'undefined') {
            this._events[name] = [];
        }
        this._events[name].push(callback);
    }

    /**
     * Unsubscribe a callback from the given event
     *
     * @param {String} name - Event name
     * @param {Function} callback
     * @returns {Object}
     */
    off(name, callback) {
        const index = (this._events[name] || []).indexOf(callback);
        if (index > -1) {
            this._events[name].splice(index, 1);
        }
    }

    /**
     * Dispatch the custom event and run all registered callbacks for a given
     * event. name and call them all with the dialog element as first argument,
     * event as second argument (if any). Also dispatch a custom event on the
     * DOM element itself to make it possible to react to the lifecycle of
     * auto-instantiated dialogs.
     *
     * @param {String} name - Event name
     * @param {Event} event
     * @access private
     */
    _dispatchEvent(name) {
        const callbacks = this._events[name] || [];
        const customEvent = new CustomEvent(name, { detail: event });

        this.$module.dispatchEvent(customEvent);

        callbacks.forEach((callback) => callback(this.$module, event));
    }

    /**
     * Handle ESCAPE and TAB key presses while the dialog element is shown.
     *
     * @param {Event} event
     */
    handleKeyDown(event) {
        // In case of nested dialog elements, ensure that pressing ESCAPE only closes the
        // top most (i.e. active) element, allowing successive key presses to close
        // them one after another.

        // TODO: focus still has to actually move inside the dialog when opened
        // if (!this.$module.contains(document.activeElement)) return;

        // On ESCAPE key press, hide the dialog element – unless it’s an alert
        if (event.which === ESCAPE_KEY && this.isShown && this.isDismissable) {
            event.preventDefault();
            this.hide(event);
        }

        // On TAB key press, ensure the focus stays trapped inside the dialog element

        if (this.isShown && event.which === TAB_KEY) {
            trapFocus(this.$module, event);
        }
    }

    /**
     * Move the focus inside the dialog.
     *
     * If present, set it to the first element with an `[autofocus]` attribute,
     * else pick the first programmatically determined focusable element.
     */
    moveFocusToDialog() {
        const target =
            this.$module.querySelector('[autofocus]') ||
            getFocusableChildren(this.$module)[0];

        if (target) target.focus();
    }

    /**
     * Ensure the focus remains within the dialog after the page has lost focus,
     * e.g. when a user focuses the URL bar of the browser, and then starts
     * tabbing again.
     *
     * @param event
     */
    maintainFocus(event) {
        const isInModal = event.target.closest('[aria-modal="true"]');
        if (!isInModal) this.moveFocusToDialog();
    }
}

function trapFocus(node, event) {
    const $allFocusable = getFocusableChildren(node);
    const $lastFocusable = $allFocusable.length - 1;
    const focusedIndex = $allFocusable.indexOf(document.activeElement);
    const isShiftKeyPressed = event.shiftKey;

    if (isShiftKeyPressed && focusedIndex === 0) {
        event.preventDefault();
        $allFocusable[$lastFocusable].focus();
    } else if (!isShiftKeyPressed && focusedIndex === $lastFocusable) {
        event.preventDefault();
        $allFocusable[0].focus();
    }
}

function isVisible() {
    return (element) =>
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length;
}

function getFocusableChildren(rootNode) {
    const elements = [...rootNode.querySelectorAll(FOCUSABLE_NODES.join(','))];

    return elements.filter(isVisible);
}

export default Dialog;
