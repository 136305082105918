import { Fancybox } from '@fancyapps/ui';

Fancybox.bind('[data-lightbox]', {
    infinite: false,
    dragToClose: true,
    Toolbar: false,
    closeButton: 'outside',
    hideScrollbar: true,

    // Specify the attribute to get the gallery group parameter from,
    // needed since we use `data-lightbox` instead of `data-fancybox`
    groupAttr: 'data-group',

    // mainClass: xxx;
    // showClass: 'xxx',
    // hideClass: 'xxx',

    template: {
        // Close button icon
        closeButton: `<svg class="c-icon c-icon--sm" aria-hidden="true" focusable="false">
                <use xlink:href="/assets/icons/sprite.svg#cross-24"></use>
            </svg>`,

        // Loading indicator icon
        spinner: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="24 24 48 48" aria-hidden="true" focusable="false">
                <circle cx="48" cy="48" r="20"/>
            </svg>`,

        // Main container element
        main: null,
    },

    caption: function (fancybox, carousel, slide) {
        const counter = `
        <p class="fancybox__index">
            ${slide.index + 1}
            <span class="fancybox__count">
                /${carousel.slides.length}
            </span>
        </p>`;

        slide.caption =
            carousel.slides.length > 1 ? counter + slide.caption : slide.caption;

        slide.caption = slide.credits
            ? `${slide.caption}<span class="c-media__credits"> ${slide.credits}</span>`
            : slide.caption;

        return slide.caption;
    },

    on: {
        // ready: (fancybox) => {
        //     console.log('fancybox', fancybox);
        // },
    },

    // Extend carousel options
    Carousel: {
        // classNames: {
        //     viewport: 'carousel__viewport',
        //     track: 'carousel__track',
        //     slide: 'carousel__slide',
        //     slideSelected: 'is-selected',
        // },

        // Extend navigation options (arrows, dots)
        Navigation: {
            classNames: {
                main: 'carousel__nav',
                button: 'carousel__button c-button c-button--icon-only',
                next: 'is-next',
                prev: 'is-prev',
            },

            prevTpl:
                '<svg class="c-icon" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#chevron-left-24"></use></svg>',
            nextTpl:
                '<svg class="c-icon" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#chevron-right-24"></use></svg>',
        },
    },
});

function generateCounter(fancybox, carousel, slide) {
    if (carousel.slides.length > 1) {
        return `<span class="fancybox__index">${
            slide.index + 1
        }</span><span class="fancybox__count">/${carousel.slides.length}</span><br />${
            slide.caption
        }`;
    }
}

const l10n = {
    CLOSE: 'Close',
    NEXT: 'Next',
    PREV: 'Previous',
    MODAL: 'You can close this modal content with the ESC key',
    ERROR: 'Something Went Wrong, Please Try Again Later',
    IMAGE_ERROR: 'Image Not Found',
    ELEMENT_NOT_FOUND: 'HTML Element Not Found',
    AJAX_NOT_FOUND: 'Error Loading AJAX : Not Found',
    AJAX_FORBIDDEN: 'Error Loading AJAX : Forbidden',
    IFRAME_ERROR: 'Error Loading Page',
};

const l10nCarousel = {
    NEXT: 'Next slide',
    PREV: 'Previous slide',
    GOTO: 'Go to slide %d',
};
